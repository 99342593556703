import { Component, ElementRef, ViewChild } from '@angular/core';
import { state, style, transition, animate, trigger } from '@angular/animations'
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { fromEvent, Observable, observable } from 'rxjs';
import { NgForm } from '@angular/forms';

declare var google:any;

declare var $: any;



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('slidein', [
      state('titlein', style({
        transform: 'translateY(-20px)',
        opacity: 0
      }))
    ])

  ]
})
export class AppComponent {
 
  @ViewChild('f', { static: true }) ngForm: any;

  @ViewChild('Home') Home: any;
  @ViewChild('aboutus') aboutus: any;
  @ViewChild('ourservices') ourservices: any;
  @ViewChild('technologies') technologies: any;
  @ViewChild('contact') contact: any;
  txt: any = {};
  
  constructor(public router: Router, public http: HttpClient) {
   
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    $(document).ready(function () {
      $('html, body').animate({ scrollTop: 0 }, 1000);

      $("body").tooltip({ selector: '[data-toggle=tooltip]' });
    });

    
    
      
  var myCenter = new google.maps.LatLng(16.83793734141614, 74.60919799672399);
  var mapCanvas = document.getElementById("googleMap");
  var mapOptions = {center: myCenter, zoom: 15};
  var map = new google.maps.Map(mapCanvas, mapOptions);
  var marker = new google.maps.Marker({position:myCenter});
  marker.setMap(map);
     
  }


  wachat()
  {
    if(screen.width<=100)
    {
      window.location.href="https://web.whatsapp.com/send?phone=919960640872";

    }else{
      window.location.href="https://api.whatsapp.com/send?phone=919960640872";
       
    }
  }
  
 


  home(): void {
    this.Home.nativeElement.scrollIntoView({ behavior: 'smooth' });
    $('.collapse').collapse('hide')
  }

  aboutUs(): void {


    this.aboutus.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    $("#aboutustitle").addClass('animationleft');
    $('.collapse').collapse('hide')
  }


  services(): void {
    this.ourservices.nativeElement.scrollIntoView({ behavior: 'smooth' });
    $('.collapse').collapse('hide')
  }


  tech(): void {
    this.technologies.nativeElement.scrollIntoView({ behavior: 'smooth' });
    $('.collapse').collapse('hide')
  }

  contactus(): void {
    this.contact.nativeElement.scrollIntoView({ behavior: 'smooth' });
    $('.collapse').collapse('hide')
  }


  save() {
    this.http.post("http://localhost:56168/api/ContactUs/SendEmail", this.txt).subscribe(res => {

      this.txt = {};
      this.ngForm.reset();
    
    }, (error) => {
      console.log(error);
    })
  }

}
