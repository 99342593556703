<nav id="mynav" class="navbar fixed-top navbar-expand-lg bg-light">
  <div class="container-fluid p-0 m-0">
    <a class="navbar-brand pr-4" (click)="home()" href="javascript.void(0)">
      <img src="../assets/logo/DSSlogo.png"  style="width:3em;width:3em">
    </a>
    <button class="navbar-toggler bg-dark mr-5 text-white" type="button" data-toggle="collapse"
      data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
      aria-label="Toggle navigation">
      <i class="fa fa-bars" aria-hidden="true"></i>
    </button>

    <div class="collapse navbar-collapse justify-content-start mr-5 w-100" id="navbarSupportedContent">

      <div class="row w-100">
        <div class="col-12 col-lg-9">
          <ul class="navbar-nav text-dark">
            <li class="nav-item bb active">
              <a class="nav-link text-dark" (click)="home()" href="javascript:void(0)">HOME <span
                  class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item bb">
              <a class="nav-link text-dark" (click)="aboutUs()" href="javascript:void(0)">ABOUT US</a>
            </li>
            <li class="nav-item bb">
              <a class="nav-link text-dark" (click)="services()" href="javascript:void(0)">SERVICES</a>
            </li>
            <li class="nav-item bb">
              <a class="nav-link text-dark" (click)="tech()" href="javascript:void(0)"> TECHNOLOGIES</a>
            </li>

            <li class="nav-item bb">
              <a class="nav-link text-dark" (click)="contactus()" href="javascript:void(0)">CONTACT</a>
            </li>

          </ul>
        </div>
        <div class="col-12 col-lg-3  d-flex justify-content-end flex-stratch align-items-center">
          <img src="../assets/gst.png" style="height:2.5em;width: 2.5em;">
          <a href="https://einv.dsserp.in" class="ml-1 pl-2 pr-3 text-white"
            style=" border-radius:20px;background-color: rgba(1,20,62,255);">e-Invoice + e-Way bill</a>
        </div>
      </div>
    </div>
  </div>
</nav>

<div class="row">

  <div class="container-fluid row p-0 m-0 col-12" style="background-color: rgba(1,20,62,255);">

    <div class="col-12">
      <!-- home -->
      <section #Home class="row no-gutters mt-3" style="height:100vh;">

        <!-- 1st dashboard for welcome page -->
        <div id="titlebar" class="col-12 col-lg-6 no-gutters d-flex justify-content-center align-items-center">

          <div class="txt_view actionTxtType3">
            <div class="view_tit">
              <h1 class="text-center dash-title" id="text">Welcome to <br> Dynamic Super Software </h1>
            </div>
            <div class="line_bg"></div>
            <div class="view_txt col-12 col-sm-12">
              <h5>Our Technology and Expertise, Your Vision.</h5>
              <p> To achieve total customer satisfaction, we will do our best
                to understandour customer's requirements and meet those
                requirements at all times.
              </p>
            </div>
          </div>
        </div>

        <div id="image" class="col-12 col-lg-6 d-flex justify-content-center align-items-center">

          <div style="height:auto;" class="p-0 m-0 d-none d-lg-block">
            <img src="../assets/image2.jpg" style="width: 30em;">
          </div>


        </div>
      </section>
    </div>

    <div class="col-12 d-flex justify-content-center">

      <!-- about us -->
      <section #aboutus class="col-12 col-sm-12 col-lg-11" style="height:100vh;">
        <div id="about_us" class="d-flex justify-content-center align-items-center" style="height:100vh">
          <div class="card">
            <div class="card-header text-center p-2" style="border-bottom: none;">
              <div class="card-title h2" style="text-shadow: 1px 2px 6px #888;">About Us</div>
            </div>
            <div class="card-body">
              <div class="row no-gutters">
                <div class="col-12 col-lg-6 ">


                  <p class="para1 p-0" id="aboutustitle">
                    We were established in 1997 in Sangli (Maharashtra-India) as <b> Dynamic Super Software.</b>
                    Since its inception, we have specialized in the development of expert custom software for clients in
                    a
                    variety of industries in India. Dynamic Super Software has specialized for more than Twenty years in
                    the
                    provision of expert assistance and support in all phases of the Software Development life cycle. We
                    provide the best Quality and service to the customer. <br>

                    The complementary skills of team make them an ideal design and development partnership and they have
                    consistently and repeatedly proved their ability to deliver business solutions on time and on
                    budget.
                    Strong project management skills, aspiring to build all the objectives of the company on its own
                    basis.<br>

                    Whether it is new development, upgrades or enhancements of an existing system, trouble shooting a
                    project or providing mentoring and knowledge transfer, contact us today to find out how we can
                    assist
                    you in realizing your objectives.
                  </p>

                </div>

                <div class="col d-none d-xl-block col-12  col-lg-6 ">
                  <img src="../assets/about.jpg" style="width:98%">
                </div>

              </div>
            </div>
          </div>


        </div>
      </section>
    </div>

    <div class="col-12 d-flex justify-content-center align-items-center" style="height:auto;">
      <!-- services -->
      <section #ourservices style="display:block;">
        <div class="p-4 d-lg-block"></div>
        <div class="row no-gutters ">
          <div class="col-12 mt-4 mb-4">

            <div class="text-center">
              <div class="row no-gutters">
                <div class="col-12 col-lg-4 no-gutters">
                  <hr class="ml-4 mt-4" style="border: 0.5px solid #888;">
                </div>
                <div class="col-12 col-lg-4">
                  <h1 class="text-white">Our Services</h1>
                </div>
                <div class="col-12 col-lg-4 no-gutters">
                  <hr class="mr-4 mt-4" style="border: 0.5px solid #888">
                </div>
              </div>

            </div>

          </div>

          <br>

          <div class="col-12 p-0 row no-gutters">
            <div class="d-flex justify-content-center">
              <div class="row no-gutters p-0 col-12">
                <!-- web developement -->
                <div class='col-lg col-sm-12 m-1'>
                  <div class="box actionImg8 bg-light">
                    <img src="../assets/services/web3.png" alt="This image is a css3 example image." />
                    <div class="back">
                      <div class="back_inner">
                        <span class="sub_tit">Web Development</span>

                        <span class="desc">It is platform independent and the ERP can be used on any device be it
                          desktop,
                          laptop,tablet or smartphones.</span>
                      </div>
                    </div>
                  </div>

                </div>
                
                <!-- mobile App -->
                <div class='col-lg col-sm-12 m-1'>
                  <div class="box actionImg8 bg-light">
                    <img src="../assets/services/mobile.png" alt="This image is a css3 example image." />
                    <div class="back">
                      <div class="back_inner">
                        <span class="sub_tit">Mobile Applications </span>

                        <span class="desc">Get connected to your end-users easily with pocket-friendly mobile
                          apps.</span>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- gst billing -->
                <div class='col-lg col-sm-12 m-1'>
                  <div class="box actionImg8">
                    <img src="../assets/services/einvice.png" class="bg-light"
                      alt="This image is a css3 example image." />
                    <div class="back">
                      <div class="back_inner">
                        <span class="sub_tit">e-Invoice + e-Way bill </span>

                        <span class="desc">
                          We offers real-time e-Invoicing and e-way bill with user friendly and one click solution.
                          Value
                          added
                          API
                          to integrate to e-Invoice in your Accounting /Billing /ERP Application</span>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- windows app -->
                <div class='col-lg col-sm-12 m-1'>
                  <div class="box actionImg8 bg-light">
                    <img src="../assets/services/windows.png" alt="This image is a css3 example image." />
                    <div class="back">
                      <div class="back_inner">
                        <span class="sub_tit">Windows Applications</span>

                        <span class="desc mt-0">We provide a windows based comprehensive and integrated  Applications like Lodging Management. 
                          Shopware Barcode/QR Code based Applications. Multi user and multi task
                          operating system.  
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- cloud services -->
                <div class='col-lg col-sm-12 m-1'>
                  <div class="box actionImg8">
                    <img src="../assets/services/cloud.png" alt="This image is a css3 example image." />
                    <div class="back">
                      <div class="back_inner">
                        <span class="sub_tit">Cloud Services </span>

                        <span class="desc">Dream into reality creates a small one<br />
                          to cherish for long time would like<br />
                          to experience unforgettable.</span>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-12">
            <div class="d-flex justify-content-center">
              <!-- enquiery -->
              <div class="enquirebg d-flex justify-content-center" style="height:70vh;">

                <div class="row p-4 justify-content-end w-75" style="z-index: 1;">
                  <div class="col-12  col-lg-6">
                    <div class="row">
                      <div class="col-12 col-lg-12">
                        <h3> Have a project for us?</h3>
                      </div>
                      <div class="col-12 col-lg-12">
                        <h1>Get in touch ! </h1>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6 text-white">
                    <button class="btn btn-lg bgc shadow h1" style="border-radius:0px;" (click)="contactus()">Contact us</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>




    <!-- Features -->
    <div class="col-12">
      <div id="features" class="features bg-light">
        <div class="container-fluid" data-aos="fade-up">

          <header class="section-header text-center">
            <h2>Features</h2>
            <p><b>"One click, one solution"</b> </p>
          </header>

          <div class="row w-100">

            <div class="col-12 col-lg-5">
              <img src="../assets/Capture.PNG" class="img-fluid" alt="">
            </div>

            <div class=" col-12 col-lg-7 mt-5">             
              <img src="../assets/features.PNG" class="w-100">
            </div>

          </div>

        </div>
      </div>

    </div>

    <!-- tech -->
    <div class="col-12">
      <section #technologies>
        <div id="tech" class=" mt-3">
          <div class="card mt-4">
            <div class="card-header text-center" style="border-bottom: 0px;margin-top: 5em;">
              <div class="card-title h1">Technologies</div>
            </div>

            <div class="card-body p-3">

              <div class="row p-2">
                <div class="col-12 col-lg-3 border">
                  <img src="../assets/languages/frontend.png" style="width:100%">
                </div>
                <div class="col-12 col-lg-9 d-flex align-items-center">
                  <p class="lead">
                    <strong><small> Frontend is the part of a software application that users interact with. While JavaScript,
                      HTML and CSS form the core part of any frontend. Many frameworks combine them together to
                      create high performing mobile, desktop, and web frontends faster.
                    </small> </strong>
                  </p>
                </div>
              </div>

              <hr>

              <div class="row p-2">
                <div class="col-12 col-lg-3  border">
                  <img src="../assets/languages/backend.png" style="width:100%">
                </div>
                <div class="col-12 col-lg-9 d-flex align-items-center">
                  <p class="lead">
                    <strong><small>
                      Backend development languages handle the 'behind-the-scenes' functionality of web
                      applications. It's code that
                      connects the web to a database, manages user connections, and powers the web application itself.
                      Backend
                      development works in tandem with the front end to deliver the final product to the end user.
                    </small> </strong>
                  </p>

                </div>
              </div>
              <hr>

              <div class="row p-2">
                <div class="col-12 col-lg-3 border">
                  <img src="../assets/languages/database.png" style="width:100%">
                </div>
                <div class="col-12 col-lg-9 d-flex align-items-center">
                  <p class="lead">
                    <strong><small>
                      A database is a collection of information that is organized so that it can be easily
                      accessed, managed and
                      updated. Computer databases typically contain aggregations of data records or files, containing
                      information
                      about sales transactions or interactions with specific customers.
                    </small> </strong>
                  </p>
                </div>
              </div>
              <hr>

              <div class="row p-2">
                <div class="col-12 col-lg-3 border">
                  <img src="../assets/languages/mobile.PNG" style="width:100%">
                </div>
                <div class="col-12 col-lg-9 d-flex align-items-center">
                  <p class="lead">
                    <strong><small>
                      Mobile application development is the process of creating software applications that run on
                      a
                      mobile device,
                      and a typical mobile application utilizes a network connection to work with remote computing
                      resources.
                    </small> </strong>
                  </p>
                </div>
              </div>
              <hr>
              <div class="row p-2">
                <div class="col-12 col-lg-3 border">
                  <img src="../assets/languages/cloudservices .png" style="width:100%">
                </div>
                <div class="col-12 col-lg-9 d-flex align-items-center">
                  <p class="lead">
                    <strong><small>
                      Cloud services are services available via a remote cloud computing server rather than an
                      on-site server. These
                      scalable solutions are managed by a third party and provide users with access to computing
                      services
                      such
                      as
                      analytics or networking via the internet.
                    </small> </strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <!-- contactus -->
    <div class="col-12">
      <section #contact style="padding:5px">
        <div class="mt-3 m-3">
          <div class="card contact text-center" style="margin-top:5em">
            <h2 class="text-primary p-3">Contact Us</h2>

            <div class="row no-gutters">

              <div class="col-lg-6">

                <div class="row ">
                  <div class="col-md-6">
                    <div class="info-box">
                      <i class="fa fa-map-marker"></i>
                      <h3>Address</h3>
                      <p>'Veer' 4th Lane ShreeRam nagar,<br>
                        Government Colony (East),<br>
                        Wanlesswadi Area, Sangli<br>
                        Pincode: 416414</p>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="info-box">
                      <i class="fa fa-phone"></i>
                      <a href='tel:+918805011001'>  <h3>Call Us</h3>
                      <p>+91 8805011001<b>/</b>03 <b>/</b>07</p></a>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="info-box">
                      <i class="fa fa-envelope-o"></i>
                      <a href="mailto:test@test.com"> <h3>Email Us</h3>
                      <p>info@dsserp.com</p></a>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="info-box">
                      <i class="fa fa-clock-o"></i>
                      <h3>Open Hours</h3>
                      <p>Monday - Saturday<br>10:00AM - 06:00PM</p>
                    </div>
                  </div>
                </div>

              </div>

              <div class="col-lg-6">
                <div id="googleMap" style="width:100%;height:400px;"></div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </div>


    <div class="col-12">
      <footer class="footer bg-dark pt-1">
        <div class="bottom_border">

          <div class="row">
            <div class="col-12 col-lg-6 text-center text-white">

              <h5> HEAD OFFICE</h5>
              <h6> Dynamic Super Software</h6>
              <p style="text-align:center;line-height:2;font-size: small;" class="no-gutters mb-0">
                'Veer' 4th Lane ShreeRam nagar,<br>
                Government Colony (East),<br>
                Wanleswadi Area,<br>
                Sangli – 416414
                Maharashtra (INDIA).<br>
                Tel: +91 (0233)-2601494. <br>
                Cell: +91-8805011001/03/07.<br>
                E-mail: info@dsserp.com</p>
 
            </div>
            <div class="col-12 col-lg-6 text-center text-white">

              <h5> Quick Links</h5>
              <a href="javascript:void(0)" (click)="aboutUs()" class="text-light"  style="line-height: 2;font-size: small;">ABOUT US</a><br>
              <a href="javascript:void(0)" (click)="services()" class="text-light" style="line-height: 2;font-size: small;">SERVICES</a><br>
              <a href="javascript:void(0)" (click)="tech()" class="text-light" style="line-height: 2;font-size: small;">TECHNOLOGIES</a><br>
              <a href="javascript:void(0)" (click)="contactus()" class="text-light" style="line-height: 2;font-size: small;">CONTACT</a><br>
            </div>

          </div>
        </div>

        <div class="container">

          <!--foote_bottom_ul_amrc ends here-->

          <ul class="d-flex justify-content-center" style="list-style-type: none;">
            <li class="p-3  m-2 circle d-flex justify-content-center align-items-center"><a href="https://www.facebook.com/arjunbagojikop76/"><i
                  class="fa fa-facebook-f text-light"></i></a></li>
            <li class="p-3   m-2 circle d-flex justify-content-center align-items-center"><a href="https://twitter.com/ArjunBagojikop?s=08"><i
                  class="fa fa-twitter text-light"></i></a></li>
            <li class="p-3   m-2 circle linkdin d-flex justify-content-center align-items-center"><a href="https://www.linkedin.com/in/arjun-bagojikop-10aa9232"><i
                  class="fa fa-linkedin text-light"></i></a></li>
            <li class="p-3   m-2 circle d-flex justify-content-center align-items-center"><a href="https://instagram.com/dynamic_super_software?igshid=7mr5pm2q15wb"><i
                  class="fa fa-instagram text-light"></i></a></li>
          </ul>

          <p class="copyright text-center mb-0 text-white">Copyright © Dynamic Super Software 2021</p>
          <!--social_footer_ul ends here-->
        </div>
      </footer>
    </div>
    <!-- <router-outlet></router-outlet> -->
    <!-- whatsapp chat -->
    <a id="myButton" href="javascript.void(0)" (click)="wachat()" class="whatsapp_float" target="_blank"
      rel="noopener noreferrer" style="position:fixed;bottom:35px;left:35px; z-index: 1;width:50px;">
      <img src="../assets/logo/whatsapp.png" style=" width:100%;z-index: 1" data-toggle="tooltip"
        data-bs-placement="right" title="We are hear to help you" class="bounsiffect">
    </a>
  </div>
</div>